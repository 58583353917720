import React from 'react';
import styled from 'styled-components'

const InputForm = styled.div`
  border: 4px solid black;
  border-radius: 1em;
  flex-flow: column nowrap;
  min-width: 59rem;
  justify-content: center;

`
const Title = styled.div`
  font-size: 2em;
  font-weight: 700;
  line-height: 1em;
  color: orange;
  text-align: center;
  margin-top: 1em;
`
const InputWrapper = styled.div`
  margin: 1rem;
  display: flex;
  flex-flow: column nowrap;
`
const InputItem = styled.div`
  margin: 0;
  display: flex;
`
const StyledButton = styled.button`
  background-color: #4CAF50; /* Green */
  border: none;
  border-radius: .3em;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  font-size: 2rem;
  cursor: pointer;
  min-width: 7.5em;
  margin: auto;
`

const SignUpForm = () => {
  return (
    <div>
        <InputForm>
          <Title>
            Get Your FREE Estimate Now!
          </Title>
          <form
            name="estimate_large"
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            style={{margin: 20}}
            headers='"Content-Type": "multipart/form-data"'

          > 
            {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="estimate_large" />
            
            <p>
              <label style={{color:'red'}}>*</label> <label style={{color:'black'}}>Name</label>
              <input name="name" placeholder="Name" type="text" required style={{backgroundColor: "E8E8E8", border: "3px solid F5F5F5", borderRadius: ".5em", overflow: "auto", minWidth: "55em"}} />
            </p>
            <p>
              <label style={{color:'red'}}>*</label> <label style={{color:'black'}}>Email</label>
              <input name="email" placeholder= "Email" type="email" required style={{backgroundColor: "E8E8E8", border: "3px solid F5F5F5", borderRadius: ".5em", overflow: "auto", minWidth: "55em"}}/>
            </p>
            <p>
              <label style={{color:'red'}}>*</label> <label style={{color:'black'}}>Telephone</label>
              <input name="phone" placeholder="Telephone" type="tel" required style={{backgroundColor: "E8E8E8", border: "3px solid F5F5F5", borderRadius: ".5em", overflow: "auto", minWidth: "55em"}}/>
            </p>
            <p>          
              <label style={{color:'red'}}>*</label> <label style={{color:'black'}}>City</label>
              <input name="city" placeholder="City" type="text" required style={{backgroundColor: "E8E8E8", border: "3px solid F5F5F5", borderRadius: ".5em", overflow: "auto", minWidth: "55em"}}/>
            </p>     
            <p>
              <label style={{color:'red'}}>*</label> <label style={{color:'black'}}>Select Service Type</label>
              <select name="type" required style={{backgroundColor: "E8E8E8", border: "3px solid F5F5F5", borderRadius: ".5em", overflow: "auto", minWidth: "55em"}}>
                <option value="">Choose</option>
                <option value="repair">Repair</option>
                <option value="new">New Construction</option>
              </select>
            </p>
            <p>
              <label style={{color:'red'}}>*</label> <label style={{color:'black'}}>Details</label>
              <textarea name="details" placeholder="Enter Details" rows="5" required style={{backgroundColor: "E8E8E8", border: "3px solid F5F5F5", overflow: "auto", minWidth: "55em"}}/>
            </p>
              <StyledButton>Send</StyledButton>
          </form>
        </InputForm>
    </div>
  )
}

export default SignUpForm;