import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import styled , { createGlobalStyle } from "styled-components"
import SignUpForm from "../components/contactForm";
import Header from "./header"
import "./layout.css"

const GlobalStyles = createGlobalStyle`
    @import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');

    body {
        font-family: 'Roboto:500', sans-serif;
        line-height: 1.5em;
        font-size: 1em;
    }
`

const MainContainer = styled.div`
  order: 1;
  display: flex;
  min-width: 100%;
`
const SignUpWrapper = styled.div`
  order: 2;
  width: 35%;
`
const TotalSite = styled.div`
  margin: 0 auto;
  max-width: 1100px;
  padding: 0px 1.0875rem 1.45rem;
  padding-top: 0;
  display: flex;
  flex-flow: column nowrap;
`
const Footer = styled.div`
  order: 2;
`

const Layout = ({ children }) => {
  return (
    <>
      <Header/>
      <TotalSite>
        <GlobalStyles/>
        <MainContainer>
          <SignUpWrapper>
            <SignUpForm/>
          </SignUpWrapper>
        </MainContainer>
        <Footer>
          © {new Date().getFullYear()}, The Villages Roofing       <Link to='/privacy' style={{margin: 10}}>Privacy </Link>
        </Footer>
      </TotalSite>

    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
