import React from "react"
import Layout from "../components/contactLayout"
import SEO from "../components/seo"
import styled from 'styled-components'
import {StaticQuery, graphql} from 'gatsby'

const Copy = styled.div`
  margin-top: 4rem;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1em;
  display: flex;
  text-align: center;
`

const ContactUsPage = () => {
  return (
    <StaticQuery
      query={graphql`
        {
          image: file(relativePath: { eq: "arrow.png" }) {
            childImageSharp {
              fluid(quality: 20, maxWidth: 500) {
              ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <Layout>
          <SEO title="Contact Us" />
          
          <Copy>
            Fill out this form to get your FREE estimate!
          </Copy>   


        </Layout>
      )}
    />
  )
}

export default ContactUsPage
